import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import { breadCrumbs } from "../../data/data";
import CAT_TrackingTool from "../../components/modules/cat/CAT_TrackingTool";

const ModuleTemplate = ({ solution }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.modules.map((module) => {
      // console.log(solution);
      if (module.components) {
        module.components.map((component) => {
          if (component.slug.slice(1) === slug) {
            setModules(module);
            setPageData(component);
            return setIsSlugCorrect(true);
          } else {
            return null;
          }
        });
      } else {
        if (module.slug.slice(1) === slug) {
          setModules(solution);
          setPageData(module);
          return setIsSlugCorrect(true);
        } else {
          return null;
        }
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(
      `${modules?.title === solution?.title ? "" : `${modules?.title}:`} ${
        pageData?.title
      }`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData]);

  return (
    <>
      {/* Timeline Agnostic Template Only */}
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-0 w-full min-h-[35rem]">
          <div className="flex justify-between">
            <Breadcrumbs crumbs={crumbs} />
          </div>
          <div className="my-5">
            <Tabs data={modules} />
          </div>
          <div className={`w-full pb-10 h-full min-h-[35rem]`}>
            {/* CAT Tracking Tool */}
            {pageData.title === "CAT Tracking Tool" && <CAT_TrackingTool />}
            {/* CAT Economic Analysis */}
            {pageData.title === "CAT Economic Analysis" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {/* Cross-sell Dashboard */}
            {pageData.title === "Product Recommendation Engine" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {pageData.title === "Cross-sell Analytics" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
            {/* STF Analysis */}
            {pageData.title === "STF Analysis" && (
              <PowerBI reportId={pageData.dashboards.reportId} />
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default ModuleTemplate;
