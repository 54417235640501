import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { menu } from "../../data/data";
import { Link, useLocation } from "react-router-dom";
import { UserContext } from "../../context/userContext";

const clientLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/sunlife/sunlife-logo.webp";

const Menu = () => {
  const [fullMenu, setFullMenu] = useState(false);
  const [showTitles, setShowTitles] = useState(false);
  const [hoverEffect, setHoverEffect] = useState(false);

  const location = useLocation();
  const split = location.pathname.split("/");
  const [activeTab, setActiveTab] = useState(`/${split[1]}`);
  const { pageTitle, loggedInUserData } = useContext(UserContext);

  useEffect(() => {
    setActiveTab(`/${split[1]}`);
  }, [location]);

  // On mouse enter show full menu
  const hoverOnMenu = () => {
    setHoverEffect(
      setTimeout(() => {
        setFullMenu(true);
        setShowTitles(true);
      }, 200)
    );
  };

  // On mouse leave hide full menu
  const removeHoverMenu = () => {
    clearTimeout(hoverEffect);
    setShowTitles(false);
    setFullMenu(false);
  };

  useEffect(() => {
    if (showTitles && !fullMenu) {
      setShowTitles(false);
    }
  }, [showTitles]);

  return (
    <>
      {/* Client Logo */}
      <div className="flex gap-5 h-fit items-end">
        <Link to="/home" className="w-fit">
          <img
            src={clientLogo}
            alt="icon"
            className="w-24 xl:w-28 h-6 lg:h-fit"
          />
        </Link>
      </div>
      {/* Page title */}
      <div className="hidden md:flex gap-5 h-fit items-end">
        <h1
          className={`font-bold pb-1 px-3 text-sm xl:text-lg text-primary border-primary`}
        >
          {pageTitle ? pageTitle : ""}
        </h1>
      </div>

      {/* Menu */}
      <div
        onMouseEnter={() => hoverOnMenu()}
        onMouseLeave={() => removeHoverMenu()}
        className="fixed z-20 top-16 left-0 w-fit h-full flex transition-all duration-300 ease-in-out"
      >
        {/* Nav Menu */}
        <nav
          className={`p-2 bg-white border flex flex-col pt-4 overflow-x-hidden text-xs xl:text-sm h-full gap-2 xl:gap-3 overflow-y-scroll scrollbar-hide relative transition-[width] ease duration-500 ${
            fullMenu ? "w-52 xl:w-64" : "w-12 xl:w-[52px]"
          }`}
        >
          {menu.map((item, index) => (
            <div key={index}>
              <Link
                to={item.slug}
                className={`w-full text-gray-700 hover:text-white flex items-center gap-2 relative text-base p-2 rounded-full hover:bg-primary ${
                  activeTab === item.slug
                    ? "font-semibold text-white bg-primary"
                    : ""
                }`}
                onClick={() => removeHoverMenu()}
              >
                <span className="text-sm xl:text-lg">{item.icon}</span>
                {/* {item.name} */}
                <p
                  className={`leading-3 w-full whitespace-nowrap text-[9px] xl:text-xs  ${
                    showTitles ? "block" : "hidden"
                  }`}
                >
                  {item.title}
                </p>
              </Link>
            </div>
          ))}
        </nav>
      </div>
    </>
  );
};

export default Menu;
