import React, { useState, useEffect } from "react";
import API from "../../../api/api";
import { NumberFormatter, allowScroll, preventScroll } from "../../utils/Utils";
import Select from "react-select";
import { FaXmark } from "react-icons/fa6";
import RadarChart from "../../utils/RadarChart";
import { RiSearchLine } from "react-icons/ri";
import { colourStyles } from "../../utils/Functions";
import { LuLoader2 } from "react-icons/lu";
import { CAT_columns, CAT_filterInputs } from "../../../data/modules/CAT";
import TablePaginate from "../../utils/TablePaginate";

const CAT2024_WorkInventory = () => {
  const [inputFilterData, setInputFilterData] = useState(CAT_filterInputs);
  const [workInventory, setWorkInventory] = useState(null);
  const [page, setPage] = useState(1);
  const [showEntries, setShowEntries] = useState(20);
  const [selectedCase, setSelectedCase] = useState(null);
  const [caseScores, setCaseScores] = useState(null);
  const [caseTable, setCaseTable] = useState(null);
  const [chart, openChart] = useState(false);
  const [tableLoad, setTableLoad] = useState(true);

  // fetch work inventory table from database
  const getWorkInventory = () => {
    API.post("slf/catscore2024-workinventory", inputFilterData)
      .then(async (res) => {
        // fresh or updated work inventory
        await setWorkInventory(res.data.tableData);
        setTableLoad(false);
      })
      .catch((err) => {
        // stop loader
        setTableLoad(false);
        // console.log(err);
      });
  };

  // Handle change of entries
  const handleEntriesChange = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setShowEntries(e.value);
  };

  // Search Handle
  const handleSearchFor = (e) => {
    setInputFilterData({
      ...inputFilterData,
      searchFor: e.target.value,
    });
  };

  // Whenever inputFilterData changes
  useEffect(() => {
    setTableLoad(true);
    getWorkInventory();
    setPage(1);
  }, [inputFilterData]);

  // select case operations
  useEffect(() => {
    if (selectedCase) {
      setCaseScores([
        {
          title: "Percentile Contribution Chart",
          score: selectedCase["CAT Score"],
          action: selectedCase["Sales Action"],
        },
        {
          State: selectedCase["State Pct"],
          Industry: selectedCase["Industry Pct"],
          "Effective Date": selectedCase["Effective Month Pct"],
          "Case Create Date": selectedCase["Create Month Pct"],
          Lives: selectedCase["Lives Bin Pct"],
        },
      ]);
      setCaseTable({
        ID: selectedCase["ID"],
        "Submit Date": selectedCase["Submit Date"],
        "Policy Holder Name": selectedCase["Policy Holder Name"],
        Lives: selectedCase["Lives"],
        SIC: selectedCase["SIC"],
        Industry: selectedCase["Industry"],
        State: selectedCase["State"],
        "Effective Date": selectedCase["Effective Date"],
        "Product Basket": selectedCase["Product Basket"],
        Partner: selectedCase["Partner"],
        "Sold Status": selectedCase["Sold Status"],
      });
    }
  }, [selectedCase]);

  // Open chart modal
  const openChartModal = (item) => {
    // console.log(item);
    setSelectedCase(item);
    openChart(true);
    preventScroll();
  };

  // Close above chart
  const closeChart = () => {
    openChart(false);
    setSelectedCase(null);
    allowScroll();
  };

  // sort table
  const sortByColumn = (column) => {
    setInputFilterData({
      ...inputFilterData,
      orderBy: column,
      orderStyle:
        inputFilterData.orderBy === column &&
        inputFilterData.orderStyle === "ASC"
          ? "DESC"
          : "ASC",
    });
  };

  return (
    <div className="relative">
      {/* Table starts here */}
      {workInventory === null ? (
        <div className="p-5 gap-3 flex items-center justify-center w-full">
          Loading Work Inventory
          <span className="animate-spin text-2xl">
            <LuLoader2 />
          </span>
        </div>
      ) : (
        <>
          {/* Table filters */}
          <div className="flex justify-between my-5 text-[11px]">
            {/* Show number of Entries at a time filter */}
            <div className="flex gap-2 items-center h-fit">
              Show entries:
              <Select
                className="w-full"
                classNamePrefix="select"
                isSearchable={true}
                name="underwriter"
                options={[
                  { value: "20", label: "20" },
                  { value: "40", label: "40" },
                  { value: "60", label: "60" },
                  { value: "80", label: "80" },
                  { value: "100", label: "100" },
                ]}
                onChange={(e) => handleEntriesChange(e)}
                defaultValue={{
                  value: "20",
                  label: "20",
                }}
                styles={colourStyles}
              />
            </div>
            {/* Search Filter */}
            <div className="relative h-fit">
              <RiSearchLine className="absolute top-3 left-3 w-fit text-gray-400 pr-2 text-lg border-r border-gray-400" />
              <input
                type="text"
                name="searchFor"
                id="searchFor"
                placeholder="Search Table.."
                className="rounded-lg indent-10 px-2 py-3 border focus:outline-2 w-96 focus:outline-gray-200"
                onChange={(e) => handleSearchFor(e)}
              />
            </div>
          </div>

          {/* Work Inventory Table */}
          <div className="shadow-md border-l border-r overflow-x-auto">
            <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
              <thead className="capitalize bg-primary text-gray-700">
                <tr className="p-2 transition-all duration-300 ease-in-out text-center">
                  {CAT_columns.map((column, index) => (
                    <th
                      key={index}
                      scope=""
                      className="p-1 transition-all duration-300 ease-in-out border-l first-of-type:border-0"
                    >
                      <div
                        className={`flex p-2 gap-1 justify-center relative cursor-pointer hover:underline underline-offset-2 ${
                          inputFilterData.orderBy === column
                            ? "text-tertiary"
                            : "text-white"
                        }`}
                        onClick={() => sortByColumn(column)}
                      >
                        {column}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {workInventory
                  ?.slice(showEntries * (page - 1), page * showEntries)
                  .map((item, index) => (
                    <tr
                      key={index}
                      className={`border-b hover:bg-gray-200 break-words text-center ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                      }`}
                    >
                      {CAT_columns.map((column, id) => (
                        // On click open chart showing percentile
                        <td
                          key={id}
                          className={`p-2 ${
                            (column === "Sales Action" ||
                              column === "CAT Score") &&
                            `font-semibold cursor-pointer hover:underline underline-offset-2 ${
                              item["Sales Action"] === "High Priority"
                                ? "text-green-600 bg-green-200"
                                : item["Sales Action"] === "Normal Priority"
                                ? "text-blue-400"
                                : item["Sales Action"] === "Low Priority"
                                ? "text-amber-500 bg-amber-100"
                                : item["Sales Action"] === "DTQ"
                                ? "text-red-500 bg-red-200"
                                : ""
                            } `
                          } ${
                            column === "Industry" ||
                            column === "Product Basket" ||
                            column === "Policy Holder Name"
                              ? "text-left"
                              : ""
                          }`}
                          onClick={() =>
                            (column === "CAT Score" ||
                              column === "Sales Action") &&
                            openChartModal(item)
                          }
                        >
                          {column === "CAT Score" || column === "Lives"
                            ? NumberFormatter(item[column])
                            : item[column]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
              {tableLoad && (
                <tbody className="absolute top-16 bg-black bg-opacity-30 text-white text-lg flex gap-2 items-center justify-center z-10 h-full w-full">
                  <td className="animate-spin text-xl">
                    <LuLoader2 />
                  </td>
                  <td>Loading..</td>
                </tbody>
              )}
            </table>
          </div>

          {/* Show when no rows available */}
          {workInventory.length === 0 && (
            <div className="w-full flex justify-center p-2 text-center border shadow text-xs text-gray-600">
              No matching records found
            </div>
          )}

          {/* Table Pagination */}
          <TablePaginate
            setPage={setPage}
            showEntries={showEntries}
            page={page}
            table={workInventory}
          />

          {/* Modal which opens a chart */}
          {chart && (
            <>
              <div
                className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
                onClick={() => closeChart()}
              ></div>
              {/* {console.log(caseScores)} */}
              <div className="fixed z-30 flex items-center w-[80%] justify-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <div className="bg-white px-16 py-10 rounded-lg relative w-full h-fit flex gap-10 items-center justify-center">
                  <div
                    className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                    onClick={() => closeChart()}
                  >
                    <FaXmark />
                  </div>
                  {caseTable && (
                    <div className="w-2/6 shadow-md rounded-lg h-full">
                      <div className="shadow-md rounded-lg overflow-x-auto">
                        <table className="w-full text-left text-gray-500">
                          <thead className="text-xs text-gray-100 capitalize bg-primary">
                            <tr className="p-2">
                              <th scope="col" className="p-3">
                                Attribute
                              </th>
                              <th scope="col" className="p-3 border-l">
                                Value
                              </th>
                            </tr>
                          </thead>
                          <tbody className="">
                            {Object.entries(caseTable).map(
                              ([key, value], index) => (
                                <tr
                                  key={index}
                                  className={`border-b hover:bg-gray-100 break-words text-xs`}
                                >
                                  <td className="p-2">{key}</td>
                                  <td className="p-2 border-l">{value}</td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                  <div className="w-4/6 border rounded-lg shadow-md h-full">
                    <RadarChart data={caseScores} module={"Lead Prioritizer"} />
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CAT2024_WorkInventory;
