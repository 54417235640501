import React, { useContext, useEffect } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, otherModules } from "../../data/data";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const OtherModules = () => {
  const { setPageTitle } = useContext(UserContext);

  useEffect(() => {
    setPageTitle(otherModules.title);
  }, []);

  return (
    <section className="p-5 pt-0 w-full min-h-[35rem]">
      <Breadcrumbs crumbs={breadCrumbs(otherModules)} />
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out">
        <div className="px-10 grid grid-cols-4 h-full gap-5 transition-all duration-300 ease-in-out">
          {otherModules.modules?.map((module, index) => (
            <div
              key={index}
              className="relative grid p-2 h-full rounded-lg gap-1 items-center text-xs transition-all duration-300 ease-in-out"
            >
              <Link
                to={
                  module.components
                    ? otherModules?.slug + module?.components[0]?.slug
                    : otherModules?.slug + module?.slug
                }
                className="w-full h-full p-10 flex flex-col gap-2 relative hover:text-white border hover:border-primary bg-primary bg-opacity-5 hover:bg-opacity-100 rounded-2xl shadow-lg"
              >
                <span className="absolute text-4xl top-2 right-3 opacity-10">
                  {otherModules.icon}
                </span>
                <div className="flex gap-2 items-center">
                  <span className="p-1 rounded-full text-2xl w-fit text-tertiary">
                    {module.icon}
                  </span>
                  <h3 className="font-semibold text-sm">{module.title}</h3>
                </div>
                <p className="">{module.description}</p>
              </Link>
            </div>
          ))}
        </div>
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default OtherModules;
