// ***************************************************************   All Icons Import  ***************************************************************
import { dashboards } from "./dashboards";
import { FaSortNumericUp, FaTools } from "react-icons/fa";
import {
  FaCalculator,
  FaHandHoldingDollar,
  FaUserTie,
  FaRetweet,
  FaTable,
  FaScrewdriverWrench,
  FaChartColumn,
  FaChartLine,
  FaServer,
  FaListCheck,
  FaChalkboardUser,
  FaAddressCard,
  FaAddressBook,
  FaUsersLine,
  FaDolly,
  FaDivide,
} from "react-icons/fa6";
import { GoHomeFill } from "react-icons/go";
import { LuLayoutDashboard } from "react-icons/lu";
import { TfiMoreAlt } from "react-icons/tfi";
import { LiaCartArrowDownSolid } from "react-icons/lia";
import { dataDictionary } from "./modules/Renewals";

// Images
const cat_modelperformance2023 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/sunlife/cat-model/model-performance-2023.webp";
const cat_modelperformance2024 =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnaker.online/client/sunlife/cat-model/model-performance-2024.webp";

// ***************************************************************   All Industries  ***************************************************************

export const q1_2024_refresh = {
  title: "Q1 2024 Refresh",
  slug: "/q1_2024_refresh",
  name: "q1_2024_refresh",
};

export const q3_2023_refresh = {
  title: "Q3 2023 Refresh",
  slug: "/q3_2023_refresh",
  name: "q3_2023_refresh",
};

export const q3_2023_deployment = {
  title: "Q3 2023 Deployment",
  slug: "/q3_2023_deployment",
  name: "q3_2023_deployment",
};

export const q1_2023_deployment = {
  title: "Q1 2023 Development ",
  slug: "/q1_2023_deployment",
  name: "q1_2023_deployment",
};

export const refresh2024 = {
  title: "2024 Refresh",
  slug: "/2024_refresh",
  name: "2024_refresh",
};

export const refresh2023 = {
  title: "2023 Refresh",
  slug: "/2023_refresh",
  name: "2023_refresh",
};

// ***************************************************************   All Solutions  ***************************************************************

// Case Analyzer Tool
export const caseAnalyzerTool = {
  title: "Case Analyzer Tool",
  slug: "/cat",
  description: "",
  alias: "CAT Model",
  icon: <FaSortNumericUp />,
  deployments: [refresh2024, refresh2023],
  refresh2024: [
    {
      title: "Work Inventory",
      icon: <FaTable />,
      slug: "/work-inventory",
      description:
        "Sample inventory of historical prospect cases with CAT Score and priority threshold",
    },
    {
      title: "Score Calculator",
      icon: <FaCalculator />,
      slug: "/score-calculator",
      description:
        "Calculate the CAT Score for a prospect with the selected attributes",
    },
    {
      title: "Score Distribution",
      icon: <FaChartColumn />,
      slug: "/score-distribution",
      description: "CAT Score distribution with attribute slicers",
      dashboards: { ...dashboards.caseAnalyzerTool.scoreDistribution },
    },
    {
      title: "Attribute Propensity",
      icon: <FaTools />,
      slug: "/attribute-propensity",
      description:
        "Sold propensity across prospect case variables and attributes",
      dashboards: { ...dashboards.caseAnalyzerTool.attributePropensity },
    },
    {
      title: "Model Performance",
      icon: <FaChartLine />,
      slug: "/model-performance",
      description:
        "Assessment of model statistical performance metrics and comparison to prior year deployment",
      images: [cat_modelperformance2024],
    },
  ],
  refresh2023: [
    {
      title: "Work Inventory",
      icon: <FaTable />,
      slug: "/work-inventory",
      description:
        "Sample inventory of historical prospect cases with CAT Score and priority threshold",
    },
    {
      title: "Score Calculator",
      icon: <FaCalculator />,
      slug: "/score-calculator",
      description:
        "Calculate the CAT Score for a prospect with the selected attributes",
    },
    {
      title: "Score Distribution",
      icon: <FaChartColumn />,
      slug: "/score-distribution",
      description: "CAT Score distribution with attribute slicers",
      dashboards: { ...dashboards.caseAnalyzerTool.scoreDistribution },
    },
    {
      title: "Attribute Propensity",
      icon: <FaTools />,
      slug: "/attribute-propensity",
      description:
        "Sold propensity across prospect case variables and attributes",
      dashboards: { ...dashboards.caseAnalyzerTool.attributePropensity },
    },
    {
      title: "Model Performance",
      icon: <FaChartLine />,
      slug: "/model-performance",
      description:
        "Assessment of model statistical performance metrics and comparison to prior year deployment",
      images: [cat_modelperformance2023],
    },
  ],
};

// Renewals Analyzer Tool
export const renewalAnalyzerTool = {
  title: "Renewal Analyzer Tool",
  slug: "/rat",
  description: "",
  icon: <FaRetweet />,
  deployments: [q1_2024_refresh, q3_2023_refresh, q1_2023_deployment],
  q1_2024_refresh: [
    // {
    //   title: "Renewals Summary",
    //   icon: <FaListCheck />,
    //   slug: "/renewals-summary",
    //   description: "Summary of data in scope for Renewal Analyzer Tool",
    //   dashboards: { ...dashboards.renewalAnalyzerTool.renewalsSummary },
    // },
    {
      title: "Attribute Analysis",
      icon: <FaScrewdriverWrench />,
      slug: "/attribute-analysis",
      description:
        "Comparison of renewal and profitability metrics and trends across renewal sponsor attributes",
      dashboards: { ...dashboards.renewalAnalyzerTool.attributeAnalysis },
    },
    // {
    //   title: "Watch List",
    //   icon: <FaChalkboardUser />,
    //   slug: "/watch-list",
    //   description:
    //     "Identify persistently unprofitable sponsors for targeted renewal action",
    //   dashboards: { ...dashboards.renewalAnalyzerTool.targetSponsorList },
    // },
    {
      title: "Watch List - Core / Small Business",
      icon: <FaChalkboardUser />,
      slug: "/watch-list-core-small-business",
      description:
        "Identify persistently unprofitable Core / Small Business sponsors for targeted renewal action",
      dashboards: {
        ...dashboards.renewalAnalyzerTool.targetSponsorListCoreSmallBusiness,
      },
    },
    {
      title: "Watch List - National Accounts",
      icon: <FaChalkboardUser />,
      slug: "/watch-list-national-accounts",
      description:
        "Identify persistently unprofitable National Accounts sponsors for targeted renewal action",
      dashboards: {
        ...dashboards.renewalAnalyzerTool.targetSponsorListNationalAccounts,
      },
    },
    {
      title: "Active Sponsor Scorecard",
      icon: <FaUserTie />,
      slug: "/active-sponsor-scorecard",
      description:
        "Holistic view of sponsor-specific renewal and profitability (pol-by-pol) metrics and trends",
      dashboards: { ...dashboards.renewalAnalyzerTool.activeSponsorDetails },
    },
    {
      title: "Data Dictionary",
      icon: <FaAddressBook />,
      slug: "/data-dictionary",
      description: "A data dictionary for Renewal Analyzer Tool",
      dataDictionary: dataDictionary,
    },
  ],
  q3_2023_refresh: [
    {
      title: "Renewals Summary",
      icon: <FaListCheck />,
      slug: "/renewals-summary",
      description: "Summary of data in scope for Renewal Analyzer Tool",
      dashboards: { ...dashboards.renewalAnalyzerTool.renewalsSummary },
    },
    {
      title: "Attribute Analysis",
      icon: <FaScrewdriverWrench />,
      slug: "/attribute-analysis",
      description:
        "Comparison of renewal and profitability metrics and trends across renewal sponsor attributes",
      dashboards: { ...dashboards.renewalAnalyzerTool.attributeAnalysis },
    },
    {
      title: "Watch List",
      icon: <FaChalkboardUser />,
      slug: "/watch-list",
      description:
        "Identify persistently unprofitable sponsors for targeted renewal action",
      dashboards: { ...dashboards.renewalAnalyzerTool.targetSponsorList },
    },
    {
      title: "Active Sponsor Scorecard",
      icon: <FaUserTie />,
      slug: "/active-sponsor-scorecard",
      description:
        "Holistic view of sponsor-specific renewal and profitability (pol-by-pol) metrics and trends",
      dashboards: { ...dashboards.renewalAnalyzerTool.activeSponsorDetails },
    },
  ],
  q1_2023_deployment: [
    {
      title: "Renewals Summary",
      icon: <FaListCheck />,
      slug: "/renewals-summary",
      description: "Summary of data in scope for Renewal Analyzer Tool",
      dashboards: { ...dashboards.renewalAnalyzerTool.renewalsSummary },
    },
    {
      title: "Attribute Analysis",
      icon: <FaScrewdriverWrench />,
      slug: "/attribute-analysis",
      description:
        "Comparison of renewal and profitability metrics and trends across renewal sponsor attributes",
      dashboards: { ...dashboards.renewalAnalyzerTool.attributeAnalysis },
    },
    {
      title: "Watch List",
      icon: <FaChalkboardUser />,
      slug: "/watch-list",
      description:
        "Identify persistently unprofitable sponsors for targeted renewal action",
      dashboards: { ...dashboards.renewalAnalyzerTool.targetSponsorList },
    },
    {
      title: "Active Sponsor Scorecard",
      icon: <FaUserTie />,
      slug: "/active-sponsor-scorecard",
      description:
        "Holistic view of sponsor-specific renewal and profitability (pol-by-pol) metrics and trends",
      dashboards: { ...dashboards.renewalAnalyzerTool.activeSponsorDetails },
    },
  ],
};

// Broker Analyzer Tool
export const brokerAnalyzerTool = {
  title: "Broker Analyzer Tool",
  slug: "/bat",
  description: "",
  icon: <FaUserTie />,
  deployments: [q1_2024_refresh, q3_2023_deployment],
  q1_2024_refresh: [
    {
      title: "Broker Factbase",
      icon: <FaAddressBook />,
      slug: "/broker-factbase",
      description:
        "Aggregation of policy-level details for renewals and profitability (pol-by-pol) data for all BDS Broker Firms",
      // comment: "(for active inforce sponsors only)",
      dashboards: { ...dashboards.brokerAnalyzerTool.brokerFactbase },
    },
    {
      title: "GA Factbase",
      icon: <FaAddressBook />,
      slug: "/ga-factbase",
      description:
        "Aggregation of policy-level details for inforce, renewals and profitability (pol-by-pol) data for all General Agencies (GA)",
      dashboards: { ...dashboards.brokerAnalyzerTool.gaFactabase },
    },
    {
      title: "Broker/GA Scorecard",
      icon: <FaAddressCard />,
      slug: "/broker-ga-scorecard",
      description:
        "Holistic view of broker-specific renewal and profitability (pol-by-pol) metrics and trends",
      dashboards: { ...dashboards.brokerAnalyzerTool.brokerScorecard },
    },
    {
      title: "Top 10 Brokers",
      icon: <FaUsersLine />,
      slug: "/top-10-brokers",
      // slug: "#",
      description:
        "Comparison of key performance indicators across Sun Life’s top 10 brokers by premium",
      // comment: "(for active inforce sponsors only)",
      dashboards: { ...dashboards.brokerAnalyzerTool.topBrokerComparison },
    },
    {
      title: "Data Dictionary",
      icon: <FaAddressBook />,
      slug: "/data-dictionary",
      description: "A data dictionary for Broker Analyzer Tool",
      dataDictionary: dataDictionary,
    },
  ],
  q3_2023_deployment: [
    {
      title: "Broker Factbase",
      icon: <FaAddressBook />,
      slug: "/broker-factbase",
      description:
        "Aggregation of policy-level details for renewals and profitability (pol-by-pol) data for all BDS Broker Firms",
      comment: "(for active inforce sponsors only)",
      dashboards: { ...dashboards.brokerAnalyzerTool.brokerFactbase },
    },
    {
      title: "Broker/GA Scorecard",
      icon: <FaAddressCard />,
      slug: "/broker-ga-scorecard",
      description:
        "Holistic view of broker-specific renewal and profitability (pol-by-pol) metrics and trends",
      dashboards: { ...dashboards.brokerAnalyzerTool.brokerScorecard },
    },
    {
      title: "Top 10 Brokers",
      icon: <FaUsersLine />,
      slug: "/top-10-brokers",
      description:
        "Comparison of key performance indicators across Sun Life’s top 10 brokers by premium",
      comment: "(for active inforce sponsors only)",
      dashboards: { ...dashboards.brokerAnalyzerTool.topBrokerComparison },
    },
  ],
};

// Other Modules
export const otherModules = {
  title: "Other Modules",
  slug: "/other-modules",
  icon: <TfiMoreAlt />,
  modules: [
    // {
    //   title: "CAT Tracking Tool",
    //   icon: <FaServer />,
    //   slug: "/cat-tracking-tool",
    //   description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    // },
    // {
    //   title: "CAT Economic Analysis",
    //   icon: <FaHandHoldingDollar />,
    //   slug: "/cat-economic-analysis",
    //   description: "Lorem ipsum dolor sit amet consectetur adipisicing elit.",
    //   dashboards: {
    //     ...dashboards.caseAnalyzerTool.catEconomicAnalysis.timelineAgnostic,
    //   },
    // },
    {
      title: "Product Recommendation Engine",
      icon: <FaDolly />,
      slug: "/product-recommendation-engine",
      description: "Tool to recommend new products for cross-sell",
      dashboards: {
        ...dashboards.crossSellDashboard.productRecommendationEngine
          .timelineAgnostic,
      },
    },
    {
      title: "Cross-sell Analytics",
      icon: <FaChartLine />,
      slug: "/cross-sell-analytics",
      description: "Summary of product penetration by in-force customers",
      dashboards: {
        ...dashboards.crossSellDashboard.crossSellAnalysis.timelineAgnostic,
      },
    },
    {
      title: "STF Analysis",
      slug: "/stf-analysis",
      description:
        "Sold benefits from 2018-2020 July YTD spanning Life, Disability, Dental & Vision",
      icon: <FaDivide />,
      status: "inactive",
      dashboards: { ...dashboards.stfAnalysis.timelineAgnostic },
    },
  ],
};

// Breadcrumbs
export const breadCrumbs = (item) => [
  {
    title: "Home",
    url: "/home",
  },
  {
    title: item.title,
    url: item.slug,
  },
];

// ***************************************************************  Solutions in Home  ***************************************************************
export const solutions = [
  caseAnalyzerTool,
  renewalAnalyzerTool,
  brokerAnalyzerTool,
  otherModules,
];

// ***************************************************************   Menu  ***************************************************************
export const menu = [
  {
    title: "Home",
    slug: "/home",
    icon: <GoHomeFill />,
  },
  ...solutions,
];

export const home = [caseAnalyzerTool, renewalAnalyzerTool, brokerAnalyzerTool];
