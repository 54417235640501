import React, { useState, useContext, useEffect } from "react";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import { Link } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, brokerAnalyzerTool, q3_2023_dev } from "../../data/data";
import FilterDeployment from "../../components/modules/FilterDeployment";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const BrokerAnalyzerTool = () => {
  const { setPageTitle } = useContext(UserContext);
  const [deployment, setDeployment] = useState(
    sessionStorage.getItem("bat-deployment-title") &&
      sessionStorage.getItem("bat-deployment-value")
      ? {
          title: sessionStorage.getItem("bat-deployment-title"),
          slug: sessionStorage.getItem("bat-deployment-value"),
        }
      : {
          title: brokerAnalyzerTool.deployments[0].title,
          slug: brokerAnalyzerTool.deployments[0].slug.split("/")[1],
        }
  );

  useEffect(() => {
    setPageTitle(brokerAnalyzerTool.title + ": " + deployment.title);
  }, [deployment]);

  return (
    <section className="p-5 pt-0 w-full min-h-[35rem]">
      <div className="flex flex-col md:flex-row justify-between">
        <Breadcrumbs crumbs={breadCrumbs(brokerAnalyzerTool)} />
        <FilterDeployment
          deployments={brokerAnalyzerTool.deployments}
          setDeployment={setDeployment}
          selected={deployment}
          prefix={"bat-"}
        />
      </div>
      <div className="w-full my-10 pb-10 relative flex flex-col gap-20 transition-all duration-300 ease-in-out">
        <div className="md:px-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 h-full gap-5 transition-all duration-300 ease-in-out">
          {brokerAnalyzerTool[deployment.slug]?.map((module, index) => (
            <div
              key={index}
              className="relative grid p-2 h-full rounded-lg gap-1 items-center text-xs  transition-all duration-300 ease-in-out"
            >
              <Link
                to={
                  module.slug === "#"
                    ? "#"
                    : brokerAnalyzerTool.slug +
                      `/${deployment.slug}` +
                      module.slug
                }
                className={`w-full h-full p-10 flex flex-col gap-2 relative hover:text-white border bg-primary bg-opacity-5 rounded-2xl shadow-lg ${
                  module.slug === "#"
                    ? "cursor-default"
                    : "cursor-pointer hover:border-primary hover:bg-opacity-100"
                }`}
              >
                <span className="absolute text-4xl top-2 right-3 opacity-10">
                  {brokerAnalyzerTool.icon}
                </span>
                <div className="flex gap-2 items-center">
                  <span className="p-1 rounded-full text-2xl w-fit text-tertiary">
                    {module.icon}
                  </span>
                  <h3 className="font-semibold text-sm">{module.title}</h3>
                </div>
                <p className="">
                  {module.description}{" "}
                  <span className="italic">{module.comment}</span>
                </p>
              </Link>
            </div>
          ))}
        </div>
        {/* <div className="px-10 -mt-10">
          <p className="text-sm italic">
            Preliminary dashboard views uploaded; views are subject to change in
            subsequent iterations
          </p>
        </div> */}
      </div>
      {/* Powered By Spinnaker */}
      <div className="fixed hidden md:flex flex-col gap-10 right-5 bottom-5">
        <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
          <p className="text-[10px] 2xl:text-xs">Powered by</p>
          <img
            src={spinnakerLogo}
            alt="powered by spinnaker analytics"
            className="w-28"
          />
        </div>
      </div>
    </section>
  );
};

export default BrokerAnalyzerTool;
