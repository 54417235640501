import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import { breadCrumbs, caseAnalyzerTool } from "../../data/data";
import Images from "../../components/modules/Images";
import CAT2023_ScoreCalculator from "../../components/modules/cat/CAT2023_ScoreCalculator";
import CAT2023_WorkInventory from "../../components/modules/cat/CAT2023_WorkInventory";
import SelectDeployment from "../../components/modules/SelectDeployment";

const Refresh2023 = ({ solution, deployment }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);

  useEffect(() => {
    solution.refresh2023.map((module) => {
      if (module?.slug.slice(1) === slug) {
        setModules(solution.refresh2023);
        setPageData(module);
        return setIsSlugCorrect(true);
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(
      `${deployment.title} ${solution.alias ? solution.alias + " :" : ":"} ${
        pageData?.title
      }`
    );
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData, deployment]);

  return (
    <>
      {/* Timeline Agnostic Template Only */}
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-0 w-full md:min-h-[35rem]">
          <div className="flex flex-col md:flex-row justify-between">
            <Breadcrumbs crumbs={crumbs} />
            <SelectDeployment
              deployments={solution.deployments}
              selected={[deployment]}
            />
          </div>
          <div className="my-5">
            <Tabs data={modules} deployment={solution.slug + deployment.slug} />
          </div>
          <div className={`w-full pb-10 h-full md:min-h-[35rem]`}>
            {/* Case AnalyzerTool 2023 */}
            {caseAnalyzerTool.refresh2023.map(
              (module, index) =>
                pageData.title === module.title &&
                (pageData.dashboards ? (
                  <PowerBI
                    key={index}
                    reportId={pageData?.dashboards?.refresh2023?.reportId}
                    note={null}
                  />
                ) : pageData.images ? (
                  <Images data={pageData} />
                ) : pageData.title === "Score Calculator" ? (
                  <CAT2023_ScoreCalculator />
                ) : pageData.title === "Work Inventory" ? (
                  <CAT2023_WorkInventory />
                ) : (
                  ""
                ))
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Refresh2023;
