import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { colourStyles } from "../utils/Functions";
import { createTimelineOptions } from "../../data/staticData";

const SelectDeployment = ({ deployments, selected }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const onChangeSelect = (e) => {
    // console.log(e);
    let path = pathname.split("/");
    // Find and change second last value which is industry level
    path[path.length - 2] = e.value;
    const newPath = path.join("/");
    navigate(newPath);
  };
  
  // console.log(deployments);

  return (
    <div className="text-xs flex gap-2 items-center">
      <label htmlFor="timeline">Select Deployment:</label>
      <Select
        styles={colourStyles}
        name="timeline"
        options={createTimelineOptions(deployments)}
        className="w-60"
        onChange={(e) => onChangeSelect(e)}
        defaultValue={createTimelineOptions(selected)}
        classNamePrefix={"timeline"}
      />
    </div>
  );
};

export default SelectDeployment;
