export const dataDictionary = {
  "Inforce metrics": {
    "First Effective Date": (
      <span>
        Earliest date that the benefit, product, policy, or sponsor was inforce
      </span>
    ),
    "Inforce Sponsors": <span>Distinct count of active inforce sponsors</span>,
    "Inforce Benefits": <span>Distinct count of active inforce benefits</span>,
    "Inforce Products": (
      <span>
        Distinct count of active inforce product lines, or list of distinct
        active inforce product lines
      </span>
    ),
  },

  "Renewal metrics": {
    "Renewed Sponsors": (
      <span>
        Distinct count of active inforce sponsors that have undergone at least
        one renewal cycle
      </span>
    ),
    "Last Renewal Date": (
      <span>
        Past date of the most recent renewal cycle or historical renewal cycle
      </span>
    ),
    "Last UW Review Date": (
      <span>
        Past date of the most recent underwriter pricing review (National
        Accounts only)
      </span>
    ),
    "Next Renewal Date": (
      <span>Future date of the most immediately upcoming renewal </span>
    ),
    "Renewal Products": (
      <span>
        Distinct count of active inforce product lines, or list of distinct
        active inforce product lines at the{" "}
        <span className="font-semibold">Next Renewal Date</span>
      </span>
    ),
    "Prerenewal Premium": (
      <span>
        Annualized premium that was inforce prior to the{" "}
        <span className="font-semibold">Last Renewal Date</span>
      </span>
    ),
    "Manual Premium": (
      <span>Annualized premium need in line with manual rating</span>
    ),
    "Philosophy Premium": (
      <span>
        Annualized premium need of the initial underwriting pricing philosophy
        or “Ask” based on the underlying product and case size renewal strategy
      </span>
    ),
    "Formula Premium": (
      <span>
        Annualized premium need combining and manual and experience rating for
        experience-rated lines
      </span>
    ),
    "UW Formula Premium": (
      <span>
        <span className="font-semibold">Formula Premium</span> plus any
        reasonable underwriting methodology adjustments (e.g. shock claim)
      </span>
    ),
    "F/M Premium": (
      <span>
        Annualized premium at the time of the{" "}
        <span className="font-semibold">Last Renewal Date</span> that
        prioritizes
        <span className="font-semibold">UW Formula Premium</span> (where
        applicable) or <span className="font-semibold">Formula Premium</span>{" "}
        for experience-rated lines,{" "}
        <span className="font-semibold">Philosophy Premium</span> for
        Dental/Vision/Absence, and{" "}
        <span className="font-semibold">Manual Premium</span> for all other
        lines
      </span>
    ),
    "Renewal Premium": (
      <span>
        Annualized premium that was the final offer or{" "}
        <span className="font-semibold">Renewed Premium</span> effective at the{" "}
        <span className="font-semibold">Last Renewal Date</span>. Prioritizes
        Final App Adj Premium, if not available then sold premium, if not
        available then quoted premium, if not available then Philosophy premium
      </span>
    ),
    "Pre-RTF/M": (
      <span>
        Ratio of <span className="font-semibold">Prerenewal Premium</span> to{" "}
        <span className="font-semibold">F/M Premium</span>
      </span>
    ),
    "RTF/M": (
      <span>
        Ratio of <span className="font-semibold">Renewal Premium</span> to{" "}
        <span className="font-semibold">F/M Premium</span>
      </span>
    ),
    Action: (
      <span>
        Ratio of <span className="font-semibold">Renewal Premium</span> to{" "}
        <span className="font-semibold">Prerenewal Premium</span>
      </span>
    ),
    Outliers: (
      <span>
        Underwriting management indicator where "Y" means the benefit was
        flagged for anomalous values (e.g.{" "}
        <span className="font-semibold">Prerenewal Premium</span> = $0) or
        attributes (e.g. ASO)
      </span>
    ),
    "Rate Guarantee Expiring": (
      <span>
        `Indicator where "Yes" means the benefit came up for renewal at the{" "}
        <span className="font-semibold">Last Renewal Date</span> and "No" means
        the benefit was still within the rate guarantee period`
      </span>
    ),
  },

  "Persistency metrics": {
    "Renewing Benefits": (
      <span>
        Total distinct count of inforce benefits coming up for renewal in the
        specified period
      </span>
    ),
    "Renewed Benefits": (
      <span>
        Renewed portion of{" "}
        <span className="font-semibold">Renewing Benefits</span> that persisted
        or remained active at the{" "}
        <span className="font-semibold">Last Renewal Date</span>
      </span>
    ),
    "Termed Benefits": (
      <span>
        Terminated portion of{" "}
        <span className="font-semibold">Renewing Benefits</span> that lapsed in
        the specified period
      </span>
    ),
    "Benefit Term Rate": (
      <span>
        Ratio of Termed Benefits to{" "}
        <span className="font-semibold">Renewing Benefits</span>
      </span>
    ),
    "Benefit Persistency": (
      <span>
        Ratio of Renewed Benefits to{" "}
        <span className="font-semibold">Renewing Benefits</span>, excluding
        minor benefits or benefits under rate guarantee or outliers
      </span>
    ),
    "Renewed Premium": (
      <span>
        Renewed portion of{" "}
        <span className="font-semibold">Prerenewal Premium</span> that persisted
        or remained active at the{" "}
        <span className="font-semibold">Last Renewal Date</span>
      </span>
    ),
    "Termed Premium": (
      <span>
        Terminated portion of{" "}
        <span className="font-semibold">Prerenewal Premium</span> that lapsed at
        the Last Renewal Date
      </span>
    ),
  },

  "Pol-by-Pol metrics": {
    "Premium Revenue": (
      <span>Premiums paid to Sun Life, prorated for the inforce duration</span>
    ),
    "Incurred Claims": (
      <span>
        Actual loss experience including paid claims and incurred but not
        reported (IBNR) claims
      </span>
    ),
    "Expected Claims": (
      <span>Expected loss experience projected by actuarial</span>
    ),
    "Claim Counts": <span>Distinct count of actual claims incidences</span>,
    Commissions: (
      <span>
        Commissions and consulting fees paid to partners excluding GA fees and
        broker bonuses/contingencies
      </span>
    ),
    Expenses: (
      <span>
        Allocated administrative expenses, MGIS fees, and premium taxes
      </span>
    ),
    "Total Expenses": (
      <span>
        Sum of <span className="font-semibold">Incurred Claims</span>,
        <span className="font-semibold">Commissions</span>, and{" "}
        <span className="font-semibold">Expenses</span>
      </span>
    ),
    "UW Profit": (
      <span>
        Earned Premium net of{" "}
        <span className="font-semibold">Incurred Claims</span>,{" "}
        <span className="font-semibold">Commissions</span>, and{" "}
        <span className="font-semibold">Expenses</span>
      </span>
    ),
    "Expected Profit": (
      <span>
        Earned Premium net of Expected Claims,{" "}
        <span className="font-semibold">Commissions</span>, and{" "}
        <span className="font-semibold">Expenses</span>
      </span>
    ),
    "Loss Ratio": (
      <span>
        Ratio of <span className="font-semibold">Incurred Claims</span> to
        Earned Premium
      </span>
    ),
    "A/E Ratio": (
      <span>
        Ratio of <span className="font-semibold">Incurred Claims</span> to
        Expected Claims
      </span>
    ),
    "Commission Ratio": (
      <span>
        Ratio of <span className="font-semibold">Commissions</span> to Earned
        Premium
      </span>
    ),
    "Expense Ratio": (
      <span>
        Ratio of Expenses to{" "}
        <span className="font-semibold">Earned Premium</span>
      </span>
    ),
    "Profit Ratio": (
      <span>
        Ratio of <span className="font-semibold">UW Profit</span> to{" "}
        <span className="font-semibold">Earned Premium</span>
      </span>
    ),
    "Expected Profit Ratio": (
      <span>
        Ratio of <span className="font-semibold">Expected UW Profit</span> to{" "}
        <span className="font-semibold">Earned Premium</span>
      </span>
    ),
    "Combined Ratio": (
      <span>
        Ratio of the sum of{" "}
        <span className="font-semibold">Incurred Claims</span>,{" "}
        <span className="font-semibold">Commissions</span>, and{" "}
        <span className="font-semibold">Expenses</span> to{" "}
        <span className="font-semibold">Earned</span>
        Premium
      </span>
    ),
  },
};
