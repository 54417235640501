import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";
import ReactPaginate from "react-paginate";
import { NumberFormatter } from "./Utils";

const TablePaginate = ({ table, showEntries, page, setPage }) => {
  // Pagination Change page
  const handlePageChange = (e) => {
    setPage(e?.selected + 1);
  };

  return (
    <div className="flex z-50 flex-col items-center gap-5 w-full justify-center my-5 text-xs h-fit">
      {/* Page Select */}
      <ReactPaginate
        breakLabel="..."
        nextLabel={<FaChevronRight />}
        onPageChange={(e) => handlePageChange(e)}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={Math.ceil(table.length / showEntries)}
        previousLabel={<FaChevronLeft />}
        previousClassName={`px-10 rounded-lg ${page === 1 ? "hidden" : ""}`}
        pageClassName="bg-gray-100 rounded-lg pagination h-7 w-10 flex items-center justify-center select-none"
        renderOnZeroPageCount={null}
        containerClassName="flex bg-white rounded-lg gap-2 p-2 items-center"
        activeClassName="border rounded-lg bg-white pagination select-none"
        nextClassName={`px-10 rounded-lg ${
          page === Math.ceil(table.length / showEntries) ? "hidden" : ""
        }`}
      />
      {/* Item index numbers */}
      {table.length === 0 ? (
        <div className="select-none">
          Showing <span className="font-semibold">0</span> to{" "}
          <span className="font-semibold">0</span> of{" "}
          <span className="font-semibold">0</span> entries
        </div>
      ) : (
        <div className="select-none">
          Showing{" "}
          <span className="font-semibold">
            {NumberFormatter(Math.ceil(showEntries * (page - 1) + 1))}
          </span>{" "}
          to{" "}
          <span className="font-semibold">
            {NumberFormatter(
              Math.ceil(
                showEntries * page > table.length
                  ? table.length
                  : showEntries * page
              )
            )}
          </span>{" "}
          of{" "}
          <span className="font-semibold">{NumberFormatter(table.length)}</span>{" "}
          entries
        </div>
      )}
    </div>
  );
};

export default TablePaginate;
