import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { LuLogOut } from "react-icons/lu";
import { FaXmark } from "react-icons/fa6";
import { UserContext } from "../../context/userContext";
import API from "../../api/api";
import { account } from "../../data/account";
import { allowScroll, preventScroll } from "../utils/Utils";
import { useIsAuthenticated } from "@azure/msal-react";
import jwtDecode from "jwt-decode";

const spinnakerLogo =
  "https://spinnakerwebsiteassets.s3.amazonaws.com/spinnakeranalytics.com/images/logo/spinnaker-analytics-logo.webp";

const Options = () => {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  // Power BI context
  const {
    setIsUserLoggedIn,
    setLoggedInUserData,
    loggedInUserData,
    setPbiAccessToken,
    setSaveBeforePathPBI,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();

  // Logout function
  const handleLogOut = () => {
    allowScroll();
    if (localStorage.getItem("slf_user_info")) {
      localStorage.removeItem("slf_user_info");
      // Delete user data
      setIsUserLoggedIn(false);
      setLoggedInUserData(null);
      // Clear local storage
      localStorage.clear();
      setPbiAccessToken(null);
      // Clear session storage which contains all msal tokens
      sessionStorage.clear();
      // Navigate user to login
      window.location.reload();
      // navigate("/login");
    } else {
      // Clear Session
      const sessionToken = { session_token: "slf_user_info" };
      API.post("/user-session-logout", sessionToken)
        .then((response) => {
          // console.log(response);
          // Delete user data
          setIsUserLoggedIn(false);
          setLoggedInUserData(null);
          // Clear local storage
          localStorage.clear();
          setPbiAccessToken(null);
          // Clear session storage which contains all msal tokens
          sessionStorage.clear();
          // Navigate user to login
          window.location.reload();
          // navigate("/login");
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const getActiveTabOnRefresh = (url) => {
    const path = url.split("/");
    return path[2];
  };

  const [activeTab, setActiveTab] = useState(
    getActiveTabOnRefresh(location.pathname)
  );

  // Open options
  const openOptions = () => {
    setOptionsOpen(!optionsOpen);
    preventScroll();
  };

  // Close options
  const closeOptions = () => {
    setOptionsOpen(false);
    setAreYouSure(false);
    allowScroll();
  };

  // save location
  useEffect(() => {
    setActiveTab(getActiveTabOnRefresh(location?.pathname));
    if (
      location?.pathname !== "/microsoft/oauth2/v2.0/authorize" &&
      location?.pathname !== "/login" &&
      location?.pathname !== "/forgot-password"
    ) {
      localStorage.setItem("last-known-path", location.pathname);
    }
    setSaveBeforePathPBI(localStorage?.getItem("last-known-path"));
  }, [location]);

  // Auto logout
  useEffect(() => {
    // If JWT token is present User is looged in.
    const accessToken = JSON.parse(
      localStorage.getItem("slf_user_info")
    ).accessToken;
    const decodedToken = jwtDecode(accessToken);
    if (decodedToken.exp * 1000 < new Date().getTime()) {
      alert("User session has been expired! Login again to use the portal");
      localStorage.clear();
      setIsUserLoggedIn(false);
      window.location.reload();
      return;
    }
  }, [location]);

  return (
    <>
      {/* Open Options button */}
      <div className="flex gap-2 items-center">
        {/* Welcome Message */}
        <div className="text-xs xl:text-sm font-semibold">
          Hello {loggedInUserData.fname}!
        </div>

        {/* User icon to open menu */}
        <div
          className="cursor-pointer hover:shadow-md my-1"
          onClick={() => openOptions()}
        >
          {loggedInUserData["picture"] ? (
            <img
              className="w-8 h-8 object-cover rounded-md ring-2 ring-gray-900 pointer-events-none"
              src={loggedInUserData["picture"]}
              alt="profile"
            />
          ) : (
            <div className="w-8 h-8 rounded-md ring-2 ring-gray-400 pointer-events-none flex items-center justify-center py-[6px] px-[5px] text-sm bg-gray-100">
              {loggedInUserData.fname.substring(0, 1)}
              {loggedInUserData.lname.substring(0, 1)}
            </div>
          )}
        </div>
      </div>

      {/* Options Menu */}
      <div
        className={`fixed z-30 top-0 left-0 w-full h-full flex bg-black bg-opacity-20 ${
          optionsOpen ? "block" : "hidden"
        } transition-all duration-300 ease-in-out`}
      >
        {/* Div for empty block */}
        <div
          onClick={() => closeOptions()}
          className="w-full backdrop-blur-[.5px]"
        ></div>
        {/* Options Menu */}
        <div className="p-4 w-[1000px] md:w-96 bg-white drop-shadow-2xl rounded-l-2xl relative">
          {/* close menu button */}
          <div
            onClick={() => closeOptions()}
            className="absolute right-5 top-5 bg-gray-200 hover:bg-gray-100 p-2 px-3 rounded-lg cursor-pointer"
          >
            <FaXmark />
          </div>
          {/* User Name and icons */}
          <div className="flex gap-3 items-center my-5 w-5/6 break-words">
            {loggedInUserData["picture"] ? (
              <img
                src={loggedInUserData["picture"]}
                alt="user"
                className="w-14 h-14 rounded-2xl object-cover p-1 ring-2 ring-gray-600"
              />
            ) : (
              <div className="w-14 h-14 rounded-2xl ring-2 text-xl ring-gray-400 pointer-events-none flex items-center justify-center bg-gray-100">
                {loggedInUserData.fname.substring(0, 1)}
                {loggedInUserData.lname.substring(0, 1)}
              </div>
            )}
            <div className="flex flex-col">
              <p className="font-semibold text-base">
                {loggedInUserData.fname + " " + loggedInUserData.lname}
              </p>
              <p className="font-light">{loggedInUserData.company}</p>
            </div>
          </div>
          {/* Menu start */}
          <div className="flex flex-col gap-2 text-sm h-full relative">
            {/* Links */}
            <p className="uppercase text-[10px] font-semibold text-gray-500 select-none">
              Account
            </p>
            <Link to={account[0].url} onClick={() => closeOptions()}>
              <div
                className={`flex gap-2 items-center cursor-pointer hover:bg-gray-100 rounded-lg p-2 px-4 relative ${
                  activeTab === account[0].url.split("/")[2]
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                {activeTab === account[0].url.split("/")[2] && (
                  <span className="rounded-full w-[5px] h-4 -ml-4 bg-primary"></span>
                )}
                <span className="text-base">{account[0].icon[0]}</span>
                <span className="leading-3 text-xs">{account[0].title}</span>
              </div>
            </Link>
            <hr />
            <p className="uppercase text-[10px] font-semibold text-gray-500 mt-5 select-none">
              Security Settings
            </p>
            <Link to={account[1].url} onClick={() => closeOptions()}>
              <div
                className={`flex gap-2 items-center cursor-pointer hover:bg-gray-100 rounded-lg p-2 px-4 relative ${
                  activeTab === account[1].url.split("/")[2]
                    ? "bg-gray-100"
                    : ""
                }`}
              >
                {activeTab === account[1].url.split("/")[2] && (
                  <span className="rounded-full w-[5px] h-4 -ml-4 bg-primary"></span>
                )}
                <span className="text-base">{account[1].icon[0]}</span>
                <span className="leading-3 text-xs">{account[1].title}</span>
              </div>
            </Link>
            <hr />
            {/* Sign Out button */}
            <div className="my-5">
              {areYouSure ? (
                <div className="grid gap-3 items-center cursor-pointer p-2 leading-3 text-sm">
                  Are you sure?
                  <div className="grid grid-cols-2 gap-2 w-full">
                    <button
                      onClick={() => handleLogOut()}
                      className="bg-tertiary p-2 rounded-lg text-gray-50 hover:bg-opacity-80 leading-3 text-xs"
                    >
                      Sign Out
                    </button>
                    <button
                      onClick={() => setAreYouSure(false)}
                      className="bg-gray-300 p-2 rounded-lg hover:bg-opacity-80 leading-3 text-xs"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setAreYouSure(true)}
                  className={`flex gap-2 items-center cursor-pointer hover:bg-gray-100 rounded-lg p-2 px-4`}
                >
                  <span className="text-base">
                    <LuLogOut />
                  </span>
                  <span className="leading-3 text-xs">Sign Out</span>
                </div>
              )}
            </div>

            {/* Powered By Spinnaker */}
            <div className="fixed flex flex-col gap-10 bottom-5 right-5">
              <div className="w-full flex flex-col items-end scale-75 xl:scale-90">
                <p className="text-[10px] 2xl:text-xs">Powered by</p>
                <img
                  src={spinnakerLogo}
                  alt="powered by spinnaker analytics"
                  className="w-28"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Options;
