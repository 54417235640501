import { createContext, useState } from "react";
import { UAParser } from "ua-parser-js";
import API from "../api/api";

export const UserContext = createContext();

export const UserProvider = (props) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loggedInUserData, setLoggedInUserData] = useState({});
  const [isPowerBIAuth, setIsPowerBIAuth] = useState(false);
  const [pbiAccessToken, setPbiAccessToken] = useState(null);
  const [saveBeforePathPBI, setSaveBeforePathPBI] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);
  const [userActivity, setUserActivity] = useState(null);

  // Track user Activity
  const userActivityTracker = (
    activity,
    page = null,
    username = null,
    company = ""
  ) => {
    const parser = new UAParser();
    const result = parser.getResult();
    const activity_data = {
      username: loggedInUserData?.username ?? username ?? "Unknown user",
      role: loggedInUserData?.role ?? "Unknown",
      company: loggedInUserData?.company ?? company,
      page: page,
      device: result.device.vendor
        ? `${result.device.vendor} ${result.device.model} ${result.device.type}`
        : "None",
      browser: `${result.browser.name}, version: ${result.browser.version}`,
      activity: activity,
    };
    // console.log(activity_data);
    if (page) {
      API.post("/slf/activity", activity_data)
        .then(({ data }) => {
          // return console.log(data);
        })
        .catch((err) => {
          // return console.log(err);
        });
    }
  };

  return (
    <UserContext.Provider
      value={{
        isUserLoggedIn,
        setIsUserLoggedIn,
        loggedInUserData,
        setLoggedInUserData,
        pageTitle,
        setPageTitle,
        isPowerBIAuth,
        setIsPowerBIAuth,
        pbiAccessToken,
        setPbiAccessToken,
        saveBeforePathPBI,
        setSaveBeforePathPBI,
        userActivity,
        setUserActivity,
        userActivityTracker,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
