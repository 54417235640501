import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Tabs = ({ data, deployment }) => {
  const location = useLocation();
  const split = location.pathname.split("/");
  const [activeTab, setActiveTab] = useState(`/${split[split.length - 1]}`);

  useEffect(() => {
    setActiveTab(`/${split[split.length - 1]}`);
  }, [location]);

  return (
    <>
      {/* overflow-x-auto scrollbar-hide */}
      <div className="flex whitespace-nowrap gap-2 border-b-2 overflow-x-scroll scrollbar-hide border-primary text-gray-500 relative -mt-2">
        {/* If product has modules and deployments */}
        {data.modules
          ? data.modules?.map((item, id) => (
              <Link
                key={id}
                to={
                  item?.components
                    ? item?.components[0]?.slug === "#"
                      ? "#"
                      : data.slug + item?.components[0]?.slug
                    : item.slug === "#"
                    ? "#"
                    : data.slug + item.slug
                }
                className={`flex gap-2 items-center py-2 px-3 w-max rounded-t-md border-transparent  hover:shadow-sm hover:border-gray-300 ease-in-out ${
                  activeTab === item.slug
                    ? "text-white bg-primary"
                    : "text-gray-400 hover:bg-gray-100 drop-shadow-sm bg-gray-50"
                } `}
              >
                <span
                  className={`${
                    activeTab === item.slug ? "text-tertiary" : ""
                  }`}
                >
                  {item.icon}
                </span>
                <span className="text-xs font-semibold">{item.title}</span>
              </Link>
            ))
          : data?.map((item, id) => {
              return (
                <Link
                  key={id}
                  to={item.slug === "#" ? "#" : deployment + item.slug}
                  className={`flex gap-2 items-center py-2 px-3 w-max rounded-t-md border-transparent  hover:shadow-sm hover:border-gray-300 ease-in-out ${
                    activeTab === item.slug
                      ? "text-white bg-primary"
                      : "text-gray-400 hover:bg-gray-100 drop-shadow-sm bg-gray-50"
                  } ${item.slug === "#" ? "cursor-default" : "cursor-pointer"}`}
                >
                  <span
                    className={`${
                      activeTab === item.slug ? "text-tertiary" : ""
                    }`}
                  >
                    {item.icon}
                  </span>
                  <span className="text-xs font-semibold">{item.title}</span>
                </Link>
              );
            })}
        {}
      </div>
    </>
  );
};

export default Tabs;
