// list of product baskets for react select
export const CAT_productbaskets = [
  {
    label: "DENTAL",
    options: [
      { label: "DENTAL CORE", value: "DENTAL_CORE", index: 0 },
      { label: "DENTAL VOLUNTARY", value: "DENTAL_VOLUNTARY", index: 1 },
    ],
  },
  {
    label: "LIFE",
    options: [
      { label: "LIFE CORE", value: "LIFE_CORE", index: 3 },
      { label: "LIFE VOLUNTARY", value: "LIFE_VOLUNTARY", index: 4 },
    ],
  },
  {
    label: "CANCER/CRIT ILLNESS",
    options: [
      { label: "CANCER/CRIT ILLNESS", value: "CANCER/CRIT ILLNESS", index: 5 },
    ],
  },
  {
    label: "LTD",
    options: [{ label: "LTD", value: "LTD", index: 6 }],
  },
  {
    label: "MAXSRVCS",
    options: [{ label: "MAXSRVCS", value: "MAXSRVCS", index: 7 }],
  },
  {
    label: "PERSONAL ACCIDENT",
    options: [
      { label: "PERSONAL ACCIDENT", value: "PERSONAL ACCIDENT", index: 8 },
    ],
  },
  {
    label: "STD",
    options: [{ label: "STD", value: "STD", index: 9 }],
  },
  {
    label: "VISION",
    options: [{ label: "VISION", value: "VISION", index: 10 }],
  },
];

// states for CAT score
export const CAT_states = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

// partner names
export const CAT_partnername = ["SUN LIFE", "MGIS"];

// Market Segments
export const CAT_marketSegments = ["CORE", "SMALLBUS"];

// Form Inputs
export const CAT_formInputs = {
  case_benefit_effective_date: "",
  case_create_date: "",
  benefit_lives: "",
  market_segment_indicator: "",
  partner_name: "",
  sponsor_state: "",
  sic: "",
  product_basket: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  benefit_count: "",
};

// Action alotting function
export const CAT_scoreAction = (score) => {
  return score >= 750
    ? "High Priority"
    : score < 750 && score >= 550
    ? "Normal"
    : score < 550 && score >= 450
    ? "Low Priority"
    : "DTQ";
};

// Action alotting function
export const CAT_actionColor = (score) => {
  return score >= 750
    ? "text-green-500"
    : score < 750 && score >= 550
    ? "text-blue-400"
    : score < 550 && score >= 450
    ? "text-amber-400"
    : "text-red-500";
};

// ================================ Work Inventory ======================================
// Filtering Inputs
export const CAT_filterInputs = {
  searchFor: "",
  orderBy: "UID",
  orderStyle: "ASC",
};

// Column Names
export const CAT_columns = [
  "#",
  "ID",
  "Submit Date",
  "Policy Holder Name",
  "Lives",
  "SIC",
  "Industry",
  "State",
  "Product Basket",
  "Effective Date",
  "Partner",
  "CAT Score",
  "Sales Action",
  "Sold Status",
];

// ================================ Tracking Tool ======================================

// Filtering Inputs
export const CAT_tracking_filterInputs = {
  searchFor: "",
  underwriter: "All Underwriters",
  orderBy: "ID",
  orderStyle: "ASC",
};

// List of all columns in table
export const CAT_Track_columns = [
  "#",
  "Case ID",
  "Activity Assigned Date",
  "Activity Open Date",
  "Due Date",
  "CAT Score",
  "Recommended Prioritization",
  "Case Final Action",
  "Notes on Perspectives",
  "Underwriter",
];

// All inputs field to add a case
export const CAT_Track_inputs = {
  id:"",
  caseId: "",
  activityAssignedDate: "",
  catScore: "",
  recommendedPrioritization: "",
  dueDate: "",
  activityOpenDate: "",
  caseFinalAction: "",
  underwriter: "",
  notesOnPerspectives: "",
};

export const CAT_caseFinalAction = [
  "All lines DTQ",
  "Proposal sent",
  "Case closed/withdrawn",
  "Other (specify in notes)",
];

export const CAT_recommendedPrioritization = [
  "Auto DTQ",
  "High Priority",
  "Low Priority",
  "Normal",
];
