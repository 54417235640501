import React from "react";
import ReactApexChart from "react-apexcharts";

const RadarChart = ({ data, module }) => {
  let options = {};
  let action = "";
  if (data) {
    action = data[0]["action"];
    let color = "";
    if (
      action === "High Priority" ||
      action === "Super Preferred" ||
      action === "Very High"
    ) {
      color = "#22C55E";
    } else if (
      action === "Normal Priority" ||
      action === "Preferred" ||
      action === "Standard Plus" ||
      action === "High"
    ) {
      color = "#3B82F6";
    } else if (
      action === "Low Priority" ||
      action === "Standard" ||
      action === "Moderate"
    ) {
      color = "#F59E0B";
    } else if (
      action === "DTQ" ||
      action === "Sub Standard" ||
      action === "Low"
    ) {
      color = "#EF4444";
    } else {
      color = "#ccc";
    }

    options = {
      series: [
        {
          name: "Percentile",
          data: Object.values(data[1]).map((item) => Math.ceil(item * 100)),
        },
      ],

      options: {
        chart: {
          fontFamily: "Helvetica, Arial, sans-serif",
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radar: {
            size: 165,
            polygons: {
              strokeColors: "#e9e9e9",
              fill: {
                colors: ["#f8f8f8", "#fff"],
              },
            },
          },
        },
        title: {
          text: "Percentile Contribution Chart",
        },
        colors: [color],
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColor: color,
          strokeWidth: 2,
        },
        tooltip: {
          enabled: false,
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        xaxis: {
          // categories: [],
          categories: Object.keys(data[1]),
        },
        yaxis: {
          tickAmount: 10,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
          },
          min: 0,
          max: 100,
        },
      },
    };
  }

  return (
    <>
      {data && (
        <div className="">
          {/* Box with Lead Prioritizer data */}
          {module === "Lead Prioritizer" && (
            <div
              className={`font-semibold py-3 px-5 border-b-2 ${
                action === "High Priority" && "text-green-600"
              } ${action === "Normal Priority" && "text-blue-500"} ${
                action === "Low Priority" && "text-amber-500"
              } ${action === "DTQ" && "text-red-500"} `}
            >
              <div className="flex gap-2 w-full items-center">
                <div className="p-2 px-5 shadow-md border h-full rounded-lg w-2/6 flex flex-col">
                  <span className="text-gray-500 font-normal text-xs">
                    Case Score:
                  </span>{" "}
                  {data[0]["score"]}
                </div>
                <div className="p-2 px-5 shadow-md border h-full rounded-lg w-3/6 flex flex-col">
                  <span className="text-gray-500 font-normal text-xs">
                    Priority:
                  </span>{" "}
                  {action}
                </div>
                {/* <div className="p-2 px-5 shadow-md border h-full rounded-lg w-3/6 flex flex-col text-xs">
                  <span className="text-gray-500 font-normal">Range:</span>{" "}
                  {Object?.entries(legend)?.map(([key, value]) => (
                    <div className="flex gap-2">
                      {key}: {value}
                    </div>
                  ))}
                </div> */}
                <div className="pt-3 rounded-lg text-xs text-gray-500">
                  <span className="text-red-500 font-normal">*</span> Score is
                  based on the propensity of case being sold
                </div>
              </div>
              {/* <div className="pt-3 rounded-lg text-xs text-gray-500">
                <span className="text-red-500 font-normal">*</span> Score is
                based on the propensity of case being sold
              </div> */}
            </div>
          )}
          {/* Box with UW Engine data */}
          {module === "UW Engine" && (
            <div
              className={`font-semibold flex gap-2 w-full items-center py-3 px-5 border-b-2 ${
                action === "Super Preferred" && "text-green-600"
              } ${
                (action === "Preferred" || action === "Standard Plus") &&
                "text-blue-500"
              } ${action === "Standard" && "text-amber-500"} ${
                action === "Sub Standard" && "text-red-500"
              } `}
            >
              <div className="p-2 px-5 border shadow-lg rounded-lg w-2/6 flex flex-col">
                <span className="text-gray-500 font-normal text-xs">
                  Risk Recommended:
                </span>{" "}
                {action}
              </div>
              <div className="p-2 px-5 border shadow-lg rounded-lg w-1/6 flex flex-col">
                <span className="text-gray-500 font-normal text-xs">
                  Rating:
                </span>{" "}
                {data[0]["score"]}
              </div>
              <div className="p-2 px-5 rounded-lg text-xs text-gray-500 w-3/6">
                <span className="text-red-500 font-normal">*</span> Rating is
                based on the propensity of a case to be preferred
              </div>
            </div>
          )}
          {/* Box with Returnify data */}
          {module === "Returnify" && (
            <div
              className={`font-semibold flex gap-2 w-full items-center py-3 px-5 border-b-2 ${
                action === "Very High" && "text-green-600"
              } ${action === "High" && "text-blue-500"} ${
                action === "Moderate" && "text-amber-500"
              } ${action === "Low" && "text-red-500"} `}
            >
              <div className="p-2 px-5 border shadow-lg rounded-lg w-2/6 flex flex-col">
                <span className="text-gray-500 font-normal text-xs">
                  Probability of Return:
                </span>{" "}
                {action}
              </div>
              <div className="p-2 px-5 border shadow-lg rounded-lg w-2/6 flex flex-col">
                <span className="text-gray-500 font-normal text-xs">
                  Return Propensity Score:
                </span>{" "}
                {data[0]["score"]}
              </div>
              <div className="p-2 px-5 rounded-lg text-xs text-gray-500 w-3/6">
                <span className="text-red-500 font-normal">*</span> Rating is
                based on the propensity of a case to be targeted
              </div>
            </div>
          )}

          <div className="p-5 rounded-lg mt-5">
            <ReactApexChart
              series={options.series}
              options={options.options}
              type="radar"
              height="400"
              width="100%"
            />
          </div>
        </div>
      )}
    </>
  );
};

export default RadarChart;
