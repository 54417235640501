import React, { useState, useEffect } from "react";
import API from "../../../api/api";
import {
  DateFormatter,
  NumberFormatter,
  allowScroll,
  changeDate,
  editableDate,
  preventScroll,
  stringSplicer,
} from "../../utils/Utils";
import Select from "react-select";
import { FaPlus, FaXmark } from "react-icons/fa6";
import RadarChart from "../../utils/RadarChart";
import { RiSearchLine } from "react-icons/ri";
import { colourStyles } from "../../utils/Functions";
import { LuLoader2 } from "react-icons/lu";
import {
  CAT_Track_columns,
  CAT_Track_inputs,
  CAT_caseFinalAction,
  CAT_recommendedPrioritization,
  CAT_tracking_filterInputs,
} from "../../../data/modules/CAT";
import TablePaginate from "../../utils/TablePaginate";
import { createOptions, removeComma } from "../../../data/staticData";

const CAT_TrackingTool = () => {
  const [inputFilterData, setInputFilterData] = useState(
    CAT_tracking_filterInputs
  );
  const [inputFormData, setInputFormData] = useState(CAT_Track_inputs);
  const [trackingTable, setTrackingTable] = useState(null);
  const [underwriters, setUnderwriters] = useState(null);
  const [page, setPage] = useState(1);
  const [showEntries, setShowEntries] = useState(20);
  const [showCaseForm, setShowCaseForm] = useState(false);
  const [editCase, setEditCase] = useState(false);
  const [load, setLoad] = useState(null);
  const [tableLoad, setTableLoad] = useState(true);
  const [formNotifications, setFormNotifications] = useState(false);

  // function to fetch all underwriters
  const getAllUnderwriters = () => {
    API.get("/slf/cat_tracking_underwriters")
      .then(({ data }) => {
        // console.log(data.underwriters);
        const underwriterOptions = createOptions(data.underwriters);
        setUnderwriters(underwriterOptions);
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  // When page loads execute the following functions
  useEffect(() => {
    getAllUnderwriters();
  }, []);

  // fetch work inventory table from database
  const getTrackingTable = () => {
    API.post("slf/cat_tracking_table", inputFilterData)
      .then(async (res) => {
        // fresh or updated work inventory
        await setTrackingTable(res.data.tableData);
        setTableLoad(false);
      })
      .catch((err) => {
        // stop loader
        setTableLoad(false);
        // console.log(err);
      });
  };

  // Handle underwriter change
  const handleUnderwriterChange = (e) => {
    // console.log(e);
    setInputFilterData({
      ...inputFilterData,
      underwriter: e.value,
    });
  };

  // Handle change of entries
  const handleEntriesChange = (e) => {
    if (page > 1) {
      setPage(1);
    }
    setShowEntries(e.value);
  };

  // Search Handle
  const handleSearchFor = (e) => {
    setInputFilterData({
      ...inputFilterData,
      searchFor: e.target.value,
    });
  };

  // Whenever inputFilterData changes
  useEffect(() => {
    setTableLoad(true);
    getTrackingTable();
    setPage(1);
  }, [inputFilterData]);

  // sort table
  const sortByColumn = (column) => {
    setInputFilterData({
      ...inputFilterData,
      orderBy: column,
      orderStyle:
        inputFilterData.orderBy === column &&
        inputFilterData.orderStyle === "ASC"
          ? "DESC"
          : "ASC",
    });
  };

  // Open add case and edit case form
  const openForm = (item) => {
    console.log(item);
    setShowCaseForm(true);

    if (item) {
      setInputFormData({
        ...CAT_Track_inputs,
        id: item["#"],
        caseId: item["Case ID"],
        activityAssignedDate: editableDate(item["Activity Assigned Date"]),
        catScore: item["CAT Score"],
        recommendedPrioritization: item["Recommended Prioritization"],
        dueDate: editableDate(item["Due Date"]),
        activityOpenDate: editableDate(item["Activity Open Date"]),
        caseFinalAction: item["Case Final Action"],
        underwriter: item["Underwriter"],
        notesOnPerspectives: item["Notes on Perspectives"],
      });
    }
    preventScroll();
  };

  // Close above opened form
  const closeForm = () => {
    setShowCaseForm(false);
    setEditCase(false);
    setInputFormData(CAT_Track_inputs);
    allowScroll();
  };

  // handle input changes for adding case or editing case
  const handleOnChangeInput = (e, name) => {
    // console.log(inputFormData);
    if (e.target?.name === undefined) {
      setInputFormData({
        ...inputFormData,
        [name]: e.value,
      });
    } else if (e.target?.name === "catScore") {
      const value = e.target.value.replace(/[^\d.]/g, "");
      setInputFormData({
        ...inputFormData,
        [e.target.name]: value,
      });
    } else if (e.target?.name === "caseId") {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: parseInt(e.target?.value),
      });
    } else {
      setInputFormData({
        ...inputFormData,
        [e.target.name]: e.target.value,
      });
    }
    // console.log(e.target?.name);
  };

  // Add or edit case form
  const addCaseForm = async (e) => {
    e.preventDefault();
    setLoad("Add Case");
    // If existing case is being edited
    if (editCase) {
      await API.put("/slf/cat_tracking_addCase", inputFormData)
        .then(({ data }) => {
          // console.log(data.response);
          getTrackingTable();
          setFormNotifications(true);
          setTimeout(() => {
            setFormNotifications(false);
          }, 5000);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // If new case is added
    else {
      // to check if all inputs are filled
      const inputs = {
        caseId: inputFormData.caseId,
        activityAssignedDate: inputFormData.activityAssignedDate,
        activityOpenDate: inputFormData.activityOpenDate,
        dueDate: inputFormData.dueDate,
        recommendedPrioritization: inputFormData.recommendedPrioritization,
        catScore: inputFormData.catScore,
        caseFinalAction: inputFormData.caseFinalAction,
        underwriter: inputFormData.underwriter,
        notesOnPerspectives: inputFormData.notesOnPerspectives,
      };

      // console.log(inputs);

      let inputEmpty = false;
      // Check if all fields are filled then set required true
      await Object.values(inputs).forEach((value) => {
        if (!value) {
          inputEmpty = true;
          setFormNotifications(true);
          console.log(value);
          return;
        }
      });
      // ends function if inputs are empty
      if (inputEmpty) {
        setLoad(false);
        return;
      } else {
        setFormNotifications(false);
        console.log();
      }

      // Adding additional inputs
      // inputFormData.month = getMonth(inputFormData.effectiveDate);
      // Get scores and percentiles from existing inputs
      inputFormData.activityAssignedDate = changeDate(
        inputFormData.activityAssignedDate
      );
      inputFormData.activityOpenDate = changeDate(
        inputFormData.activityOpenDate
      );
      inputFormData.dueDate = changeDate(inputFormData.dueDate);

      await API.post("/slf/cat_tracking_addCase", inputFormData)
        .then(({ data }) => {
          closeForm();
          // To get the latest data in the section
          inputFilterData.orderBy = "ID";
          inputFilterData.orderStyle = "DESC";
          getTrackingTable();
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setLoad(null);
  };

  // Edit case function
  const editCaseFunction = (item) => {
    // console.log(item);
    setEditCase(true);
    // setSelectedCase(item);
    openForm(item);
  };

  // Reset Add Case Form
  const resetForm = () => {
    setLoad();
    setInputFormData(CAT_Track_inputs);
    setEditCase(false);
  };

  return (
    <div className="relative">
      {/* Table starts here */}
      {trackingTable === null ? (
        <div className="p-5 gap-3 flex items-center justify-center w-full">
          Loading Work Inventory
          <span className="animate-spin text-2xl">
            <LuLoader2 />
          </span>
        </div>
      ) : (
        <>
          {/* Table filters */}
          <div className="flex justify-between items-center my-5 text-[11px]">
            <div className="flex gap-10">
              {/* Show number of Entries at a time filter */}
              <div className="flex gap-2 items-center h-fit">
                Show entries:
                <Select
                  className="w-full"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="underwriter"
                  options={[
                    { value: "20", label: "20" },
                    { value: "40", label: "40" },
                    { value: "60", label: "60" },
                    { value: "80", label: "80" },
                    { value: "100", label: "100" },
                  ]}
                  onChange={(e) => handleEntriesChange(e)}
                  defaultValue={{
                    value: "20",
                    label: "20",
                  }}
                  styles={colourStyles}
                />
              </div>
              {/* Add New Track */}
              <button
                type="button"
                onClick={() => openForm()}
                className="flex gap-2 items-center h-fit text-xs border rounded-md px-3 py-2 hover:bg-tertiary hover:text-white text-gray-700"
              >
                <FaPlus className="text-lg" />
                Add New
              </button>
            </div>
            <div className="flex gap-5 items-center justify-center">
              <Select
                styles={colourStyles}
                name="timeline"
                options={[
                  { label: "All Underwriters", value: "All Underwriters" },
                  ...underwriters,
                ]}
                className="w-40"
                onChange={(e) => handleUnderwriterChange(e)}
                defaultValue={[
                  { label: "All Underwriters", value: "All Underwriters" },
                ]}
                classNamePrefix={"inventory"}
              />
              {/* Search Filter */}
              <div className="relative h-fit">
                <RiSearchLine className="absolute top-3 left-3 w-fit text-gray-400 pr-2 text-lg border-r border-gray-400" />
                <input
                  type="text"
                  name="searchFor"
                  id="searchFor"
                  placeholder="Search Table.."
                  className="rounded-lg indent-10 px-2 py-3 border focus:outline-2 w-96 focus:outline-gray-200"
                  onChange={(e) => handleSearchFor(e)}
                />
              </div>
            </div>
          </div>

          {/* CAT Tracking Table */}
          <div className="shadow-md border-l border-r overflow-x-auto">
            <table className="w-full text-[11px] text-gray-500 transition-all duration-300 ease-in-out">
              <thead className="capitalize bg-primary text-gray-700">
                <tr className="p-2 transition-all duration-300 ease-in-out text-center">
                  {CAT_Track_columns.map((column, index) => (
                    <th
                      key={index}
                      scope=""
                      className="p-1 transition-all duration-300 ease-in-out border-l first-of-type:border-0"
                    >
                      <div
                        className={`flex p-2 gap-1 justify-center relative cursor-pointer hover:underline underline-offset-2 ${
                          inputFilterData.orderBy === column
                            ? "text-tertiary"
                            : "text-white"
                        }`}
                        onClick={() => sortByColumn(column)}
                      >
                        {column}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {trackingTable
                  ?.slice(showEntries * (page - 1), page * showEntries)
                  .map((item, index) => (
                    <tr
                      key={index}
                      className={`border-b hover:bg-gray-200 break-words text-center ${
                        index % 2 === 0 ? "bg-gray-50" : "bg-gray-100"
                      }`}
                    >
                      {CAT_Track_columns.map((column, id) => (
                        // On click open chart showing percentile
                        <td
                          key={id}
                          className={`p-2 ${
                            column === "Case ID" &&
                            `font-semibold cursor-pointer hover:underline underline-offset-2 ${
                              item["Sales Action"] === "High Priority"
                                ? "text-green-600 bg-green-200"
                                : item["Sales Action"] === "Normal Priority"
                                ? "text-blue-400"
                                : item["Sales Action"] === "Low Priority"
                                ? "text-amber-500 bg-amber-100"
                                : item["Sales Action"] === "DTQ"
                                ? "text-red-500 bg-red-200"
                                : ""
                            } `
                          } ${
                            column === "Notes on Perspectives"
                              ? "text-left w-52"
                              : ""
                          }`}
                          onClick={() =>
                            column === "Case ID" && editCaseFunction(item)
                          }
                        >
                          {column === "CAT Score" || column === "Lives"
                            ? NumberFormatter(item[column])
                            : column === "Notes on Perspectives"
                            ? stringSplicer(item[column])
                            : item[column]}
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
              {tableLoad && (
                <tbody className="absolute top-16 bg-black bg-opacity-30 text-white text-lg flex gap-2 items-center justify-center z-10 h-full w-full">
                  <td className="animate-spin text-xl">
                    <LuLoader2 />
                  </td>
                  <td>Loading..</td>
                </tbody>
              )}
            </table>
          </div>

          {/* Show when no rows available */}
          {trackingTable.length === 0 && (
            <div className="w-full flex justify-center p-2 text-center border shadow text-xs text-gray-600">
              No matching records found
            </div>
          )}

          {/* Table Pagination */}
          <TablePaginate
            setPage={setPage}
            showEntries={showEntries}
            page={page}
            table={trackingTable}
          />

          {/* Add a case or edit a case modal */}
          {showCaseForm && (
            <>
              <div
                className="fixed z-20 top-0 left-0 h-screen w-screen bg-black bg-opacity-20"
                onClick={() => closeForm()}
              ></div>
              <div className="fixed z-30 flex items-center top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                <form
                  className="bg-white pt-0 p-10 rounded-lg relative"
                  onSubmit={(e) => addCaseForm(e)}
                >
                  {editCase ? (
                    <div className="p-5 -mx-10 mb-5 text-white rounded-t-lg bg-primary">
                      Edit Case ID: {inputFormData.caseId}
                    </div>
                  ) : (
                    <div className="p-5 -mx-10 mb-5 text-white rounded-t-lg bg-primary">
                      Add Case
                      <p className="text-[11px] mt-1 italic">
                        Only enter information for new business cases being
                        worked on for the first time
                      </p>
                    </div>
                  )}
                  {/* Close modal Button */}
                  <div
                    className="absolute top-5 right-5 cursor-pointer bg-gray-50 hover:bg-gray-200 px-2 py-1 rounded-lg"
                    onClick={() => closeForm()}
                  >
                    <FaXmark />
                  </div>
                  {/* Inputs */}
                  <div className="flex flex-col gap-3 text-xs ">
                    <div className="flex justify-between gap-10">
                      {/* Case ID */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="caseId">
                          Case ID:
                        </label>
                        <input
                          type="text"
                          name="caseId"
                          id="caseId"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.caseId || ""}
                          maxLength={10}
                          disabled={editCase === true}
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                      {/* Activity Assigned Date */}
                      <div className="flex flex-col gap-1">
                        <label
                          className="font-semibold"
                          htmlFor="activityAssignedDate"
                        >
                          Activity Assigned Date:
                        </label>
                        <input
                          type="date"
                          name="activityAssignedDate"
                          id="activityAssignedDate"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.activityAssignedDate || ""}
                          // pattern="\d{1,2}/\d{1,2}/\d{4}"
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                          disabled={editCase === true}
                        />
                      </div>
                      {/* CAT Score */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="catScore">
                          CAT Score:
                        </label>
                        <input
                          type="text"
                          name="catScore"
                          id="catScore"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.catScore || ""}
                          disabled={editCase === true}
                          maxLength={4}
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                      {/* Recommended Prioritization */}
                      <div className="flex flex-col gap-1">
                        <label
                          className="font-semibold"
                          htmlFor="recommendedPrioritization"
                        >
                          Recommended Prioritization:
                        </label>
                        <Select
                          styles={colourStyles}
                          isSearchable={true}
                          name="recommendedPrioritization"
                          options={createOptions(CAT_recommendedPrioritization)}
                          classNamePrefix="inventory"
                          className="w-48"
                          defaultValue={
                            inputFormData.recommendedPrioritization !== "" && {
                              label: inputFormData.recommendedPrioritization,
                              value: inputFormData.recommendedPrioritization,
                            }
                          }
                          isDisabled={editCase === true}
                          onChange={(e) =>
                            editCase === false &&
                            handleOnChangeInput(e, "recommendedPrioritization")
                          }
                        ></Select>
                      </div>
                    </div>
                    <div className="flex justify-between gap-10">
                      {/* Due Date */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="dueDate">
                          Due Date:
                        </label>
                        <input
                          type="date"
                          name="dueDate"
                          id="dueDate"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.dueDate || ""}
                          disabled={editCase === true}
                          // pattern="\d{1,2}/\d{1,2}/\d{4}"
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                      {/* Activity Open Date */}
                      <div className="flex flex-col gap-1">
                        <label
                          className="font-semibold"
                          htmlFor="activityOpenDate"
                        >
                          Activity Open Date:
                        </label>
                        <input
                          type="date"
                          name="activityOpenDate"
                          id="activityOpenDate"
                          onChange={(e) =>
                            editCase === false && handleOnChangeInput(e)
                          }
                          value={inputFormData.activityOpenDate || ""}
                          // pattern="\d{1,2}/\d{1,2}/\d{4}"
                          disabled={editCase === true}
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-48"
                        />
                      </div>
                      {/* Case Final Action */}
                      <div className="flex flex-col gap-1">
                        <label
                          className="font-semibold"
                          htmlFor="caseFinalAction"
                        >
                          Case Final Action:
                        </label>
                        <Select
                          styles={colourStyles}
                          isSearchable={true}
                          name="caseFinalAction"
                          options={createOptions(CAT_caseFinalAction)}
                          classNamePrefix="inventory"
                          className="w-48"
                          defaultValue={
                            inputFormData.caseFinalAction !== "" && {
                              label: inputFormData.caseFinalAction,
                              value: inputFormData.caseFinalAction,
                            }
                          }
                          isDisabled={editCase === true}
                          onChange={(e) =>
                            editCase === false &&
                            handleOnChangeInput(e, "caseFinalAction")
                          }
                        ></Select>
                      </div>
                      {/* Underwriter */}
                      <div className="flex flex-col gap-1">
                        <label className="font-semibold" htmlFor="underwriter">
                          Underwriter:
                        </label>
                        <Select
                          styles={colourStyles}
                          isSearchable={true}
                          name="underwriter"
                          options={underwriters}
                          classNamePrefix="inventory"
                          className="w-48"
                          defaultValue={
                            inputFormData.underwriter !== "" && {
                              label: inputFormData.underwriter,
                              value: inputFormData.underwriter,
                            }
                          }
                          isDisabled={editCase === true}
                          onChange={(e) =>
                            editCase === false &&
                            handleOnChangeInput(e, "underwriter")
                          }
                        ></Select>
                      </div>
                    </div>
                    <div className="w-full">
                      {/* Notes on Perspective */}
                      <div className="flex flex-col gap-1">
                        <label
                          className="font-semibold"
                          htmlFor="notesOnPerspectives"
                        >
                          Notes on Perspectives:
                        </label>
                        <textarea
                          name="notesOnPerspectives"
                          id="notesOnPerspectives"
                          cols="90"
                          rows="5"
                          value={inputFormData.notesOnPerspectives || ""}
                          onChange={(e) => handleOnChangeInput(e)}
                          className="border rounded-md focus:outline-none px-2 py-[10px] w-full"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  {/* Action Buttons */}
                  <div
                    className={`w-full flex gap-2 mt-3 text-sm items-center ${
                      editCase && formNotifications
                        ? "justify-between"
                        : editCase
                        ? "justify-end"
                        : "justify-between"
                    }`}
                  >
                    {editCase === false ? (
                      <span
                        className={`${formNotifications ? "text-red-500" : ""}`}
                      >
                        <sup className="text-red-500">*</sup>All Inputs fields
                        are required
                      </span>
                    ) : (
                      formNotifications && (
                        <span className="text-green-500">
                          Case updated successfully with Case ID{" "}
                          {inputFormData.caseId}
                        </span>
                      )
                    )}
                    <div className="flex gap-2 justify-end items-end">
                      {editCase === false && (
                        <input
                          type="reset"
                          value="Reset Form"
                          onClick={() => resetForm()}
                          className="px-4 py-2 bg-gray-200 rounded-md cursor-pointer"
                        />
                      )}
                      {load === "Add Case" ? (
                        <div className="flex items-center gap-2 px-4 py-2 bg-gray-300 rounded-md">
                          <span className="animate-spin">
                            <LuLoader2 />
                          </span>
                          <span>Processing...</span>
                        </div>
                      ) : (
                        <input
                          type="submit"
                          value={editCase ? "Update Case" : "Add Case"}
                          onClick={(e) => addCaseForm(e)}
                          className="px-4 py-2 bg-tertiary hover:bg-opacity-80 rounded-md text-white cursor-pointer"
                        />
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default CAT_TrackingTool;
