import React, { useEffect, useState } from "react";

const DataDictionary = ({ data }) => {
  // const [toggleView, setToggleView] = useState(true);
  const [tab, setTab] = useState(0);

  // If toggled set tab to 1st location
  // useEffect(() => {
  //   setTab(0);
  // }, [toggleView]);

  return (
    <section className="p-5 text-sm text-gray-700">
      <div className="flex justify-between">
        <h3 className="text-xl font-bold">Data Dictionary</h3>
        {/* <button
          type="button"
          onClick={() => setToggleView(!toggleView)}
          className="rounded-md px-4 py-2 bg-tertiary"
        >
          Toggle to see{" "}
          {toggleView ? "individual metric category" : "all metrics"}
        </button> */}
      </div>

      {/* {toggleView ? (
        <div className="py-5 flex gap-5">
          {Object.entries(data).map(([metric, metricData], index) => (
            <div
              key={index}
              className="w-1/4 py-2 px-4 rounded-md animate-fade-up bg-gray-100"
              // style={{ animationDuration: `${index + 1}s` }}
            >
              <h4 className="font-bold text-base text-center mt-2">{metric}</h4>
              <ul style={{ listStyleType: "disc" }} className="p-5">
                {Object.entries(metricData).map(([title, definition], id) => (
                  <li key={id} className="ml-2 py-2">
                    <span className="font-bold">{title}</span>: {definition}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ) : ( */}
      <div className="py-5">
        <div className="flex gap-5">
          {Object.keys(data).map((metric, index) => (
            <button
              type="button"
              key={index}
              onClick={() => setTab(index)}
              className={`w-1/4 py-2 px-4 rounded-t-md animate-fade-up font-bold text-base text-center mt-2 ${
                tab === index
                  ? "bg-primary text-white"
                  : "bg-gray-200 bg-opacity-50 hover:bg-primary hover:text-white"
              }`}
            >
              {metric}
            </button>
          ))}
        </div>
        {Object.values(data).map((metricData, index) => (
          <div key={index} className="animate-fade-up">
            {tab === index && (
              <ul
                style={{ listStyleType: "disc" }}
                className="p-5 rounded-b-md bg-gray-100 border-primary border"
              >
                {Object.entries(metricData).map(([title, definition], id) => (
                  <li key={id} className="ml-2 py-2">
                    <span className="font-bold">{title}</span>: {definition}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
      {/* )} */}
    </section>
  );
};

export default DataDictionary;
